const initialState = {
  data: [],
  total: 1,
  params: {},
  // allData: [],
  // designationData: [],
  // reportingManager: [],
  categoryData: [],
  subCategoryData:[],
  branchData: [],
  schoolData: [],
  brandData: [],
  productData: [],
}

const comboProductReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_PRODUCT':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_PRODUCT':
      return { ...state }
    case 'UPDATE_PRODUCT':
      return { ...state }

    case "GET_CATEGORY_DATA":
      return {
        ...state,
        categoryData: action.data,
      };
    case "GET_PRODUCT_DATA":
      return {
        ...state,
        productData: action.data,
      };
    case "CLEAR_PRODUCT_DATA":
      return {
        ...state,
        productData: [],
      };
    case "GET_ACTIVE_SCHOOL_DATA":
      return {
        ...state,
        schoolData: action.data,
      };
    case "GET_ACTIVE_BRAND_DATA":
      return {
        ...state,
        brandData: action.data,
      };

    case "GET_SUB_CATEGORY_DATA":
      return {
        ...state,
        subCategoryData: action.data,
      };

    case "GET_BRANCH_DATA":
      return {
        ...state,
        branchData: action.data,
      };

    case "CHANGE_STATUS":
      return { ...state };
    default:
      return { ...state }
  }
}
export default comboProductReducer
