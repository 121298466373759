import { combineReducers } from "redux";
import AuthUserReducer from "./AuthUserReducer";
import ContactReducer from "./ContactReducer";
import AppSettingsReducer from "./AppSettingsReducer";
import UserSettingsReducer from "./UserSettingsReducer";
import MenuListReducer from "./MenuListReducer";
import AuthReducer from "./auth";
import FormInfoReducer from "./FormInfoReducer";
import UserMasterReducer from "./master/UserMasterReducer";
import PromocodeReducer from "./master/PromocodeMasterReducer";
import BranchMasterReducer from "./master/BranchMasterReducer";
import SettingMasterReducer from "./master/SettingMasterReducer";
import NotificationMasterReducer from "./master/NotificationMasterReducer";
import CategoryMasterReducer from "./master/CategoryMasterReducer";
import CustomerReducer from "./CustomerReducer/CustomerReducer";
import ProductMasterReducer from "./master/ProductMasterReducer";
import OrdersReducer from "./reports/OrdersReducer/OrdersReducer";
import SellsReducer from "./reports/SellsReportReducer/SellsReducer";
import SubCategoryMasterReducer from "./master/SubCategoryReducer";
import BannerMasterReducer from "./master/BannerMasterReducer";
import PopupMasterReduces from "./master/PopupMasterReduces";

import BlogReducer from "./master/BlogReducer";
import SchoolReducer from "./master/SchoolMasterReducer";
import cityReducer from "./master/CityMasterReducer";
import brandReducer from "./master/BrandMasterReducer";
import comboProductReducer from "./master/ComboProductMasterReducer";
import supplierReducer from "./master/SupplierMasterReducer";
import purchaseMasterReducer from "./productInventory/PurchaseMasterReducer";
import purchaseHistoryReducer from "./productInventory/PurchaseHistoryReducer";
import faqCategoryReducer from "./master/FaqCategoryMasterReducer";
import faqReducer from "./master/FaqMasterReducer";
import warehouseReducer from "./master/WarehouseMasterReducer";
import warehouseOrdersReducer from "./reports/WarehouseOrdersReducer";
import adminOrdersReducer from "./reports/AdminOrdersReducer";
import productInventoryReducer from "./productInventory/InventoryReportReducer";
import demandedProductsReducer from "./reports/DemandedProductsReducer";
import unregisteredSchoolsReducer from "./reports/UnregisteredSchoolsReducer";
import purchaseLogsReducer from "./reports/PurchaseLogsReducer";
import abandonedCartReducer from "./reports/AbandonedCartReducer";
import dashboardReducer from "./dashboard/DashboardReducer";
import replacementReducer from "./reports/ReplacementReducer";
import warehouseReplacementOrdersReducer from "./reports/WarehouseReplacementOrdersReducer";
import importSchoolReducer from "./master/ImportSchoolReducer";
import expenseReducer from "./master/ExpenseMasterReducer";
import manageExpenseReducer from "./master/ManageExpenceReducer";
import paymentReducer from "./master/PaymentMasterReducer";

const reducers = combineReducers({
  authUser: AuthUserReducer,
  settings: AppSettingsReducer,
  ContactReducer: ContactReducer,
  UserSettingsReducer: UserSettingsReducer,
  menuListReducer: MenuListReducer,
  authReducer: AuthReducer,
  formInfoReducer: FormInfoReducer,
  userMasterReducer: UserMasterReducer,
  promocodeReducer: PromocodeReducer,
  branchMasterReducer: BranchMasterReducer,
  settingMasterReducer: SettingMasterReducer,
  notificationMasterReducer: NotificationMasterReducer,
  categoryMasterReducer: CategoryMasterReducer,
  productMasterReducer: ProductMasterReducer,
  customerReducer: CustomerReducer,
  subcategoryMasterReducer: SubCategoryMasterReducer,
  ordersReducer: OrdersReducer,
  sellsReducer: SellsReducer,
  bannerMasterReducer: BannerMasterReducer,
  popupMasterReduces: PopupMasterReduces,
  blogReducer: BlogReducer,
  schoolReducer: SchoolReducer,
  cityMasterReducer: cityReducer,
  brandMasterReducer: brandReducer,
  comboProductReducer: comboProductReducer,
  supplierMasterReducer: supplierReducer,
  purchaseMasterReducer: purchaseMasterReducer,
  purchaseHistoryReducer: purchaseHistoryReducer,
  faqCategoryReducer: faqCategoryReducer,
  faqReducer: faqReducer,
  warehouseReducer: warehouseReducer,
  warehouseOrdersReducer: warehouseOrdersReducer,
  adminOrdersReducer: adminOrdersReducer,
  productInventoryReducer: productInventoryReducer,
  demandedProductsReducer: demandedProductsReducer,
  unregisteredSchoolsReducer: unregisteredSchoolsReducer,
  purchaseLogsReducer: purchaseLogsReducer,
  abandonedCartReducer: abandonedCartReducer,
  dashboardReducer: dashboardReducer,
  replacementReducer: replacementReducer,
  warehouseReplacementOrdersReducer: warehouseReplacementOrdersReducer,
  importSchoolReducer: importSchoolReducer,
  expenseReducer: expenseReducer,
  manageExpenseReducer:manageExpenseReducer,
  paymentReducer:paymentReducer,
});

export default reducers;
