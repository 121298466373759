const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Expenses:[],
    Payments:[],
  };
  
  const manageExpenseReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_MANAGE_EXPENSE_DATA':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case 'CREATE_MANAGE_EXPENSE':
        return { ...state };
      case 'UPDATE_MANAGE_EXPENSE':
        return { ...state };
      case 'GET_EXPENSE_CATEGORY':
        return {
          ...state, 
          Expenses: action.data,
        };
      case 'GET_ACTIVE_PAYMENTS':
        return {
          ...state, 
          Payments: action.data,
        };
      
      default:
        return { ...state };
    }
  };
  
  export default manageExpenseReducer;
  