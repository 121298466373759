/**
 * Router service file
 */

// Async component
import {
  AsyncDashboard1Component,
  AsyncFormInformationComponent,
  AsyncUserMasterComponent,
  AsyncUserSettingsComponent,
  AsynCategoryComponent,
  AsynExpenseComponent,
  AsyncManageExpenseComponent,
  AsynBranchComponent,
  AsynPromocodeComponent,
  AsynSettingComponent,
  AsynNotificationComponent,
  AsynProductComponent,
  //list
  // AsynOrderListComponent,
  AsynCustomerListComponent,
  // AsynProductAvailibilityUpdateComponent,
  AsynBranchSellsReportComponent,
  AsynSellsReportComponent,
  AsynSubCategoryComponent,
  AsyncBannerMasterComponent,
  AsynPopupComponent,
  AsynBlogsComponent,
  AsynSchoolComponent,
  AsynCityComponent,
  AsynBrandComponent,
  AsynComboProductComponent,
  AsynSupplierComponent,
  AsynPurchaseComponent,
  AsynPurchaseHistoryComponent,
  AsynFaqCategoryComponent,
  AsynFaqComponent,
  AsynWarehouseComponent,
  AsynWarehouseOrdersComponent,
  AsynAdminOrdersComponent,
  AsynInventoryReportComponent,
  AsynDemandedProductsComponent,
  AsynUnregisteredComponent,
  AsynPurchaseLogsComponent,
  AsynAbandonedCartComponent,
  AsynCustomersReportComponent,
  AsynChildrensReportComponent,
  AsynReplacementReportComponent,
  AsynWarehouseReplacementOrdersComponent,
  AsynImportSchoolComponent,
  AsyncPaymentComponent
} from "routes/ComponentLoader";

const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

const routes = [
  //Dashboard's routes & permissions.
  {
    path: "dashboard",
    component: AsyncDashboard1Component,
    meta: { permission: true },
  },
  //Master's routes & permissions.
  {
    path: "master/user",
    component: AsyncUserMasterComponent,
    meta: { permission: true },
  },
  {
    path: "master/report",
    component: AsyncUserMasterComponent,
    meta: { permission: true },
  },
  {
    path: "master/formInformation",
    component: AsyncFormInformationComponent,
    meta: { permission: true },
  },
  {
    path: "master/banner",
    component: AsyncBannerMasterComponent,
    meta: { permission: true },
  },
  {
    path: "master/category",
    component: AsynCategoryComponent,
    meta: { permission: true },
  },
  {
    path: "master/expenseCategory",
    component: AsynExpenseComponent,
    meta: { permission: true },
  },
  {
    path: "master/payment",
    component: AsyncPaymentComponent,
    meta: { permission: true },
  },
  {
    path: "master/manageExpense",
    component: AsyncManageExpenseComponent,
    meta: { permission: true },
  },
  {
    path: "master/subCategory",
    component: AsynSubCategoryComponent,
    meta: { permission: true },
  },
  {
    path: "master/branch",
    component: AsynBranchComponent,
    meta: { permission: true },
  },
  {
    path: "master/promocode",
    component: AsynPromocodeComponent,
    meta: { permission: true },
  },

  {
    path: "master/notification",
    component: AsynNotificationComponent,
    meta: { permission: true },
  },
  {
    path: "master/setting",
    component: AsynSettingComponent,
    meta: { permission: true },
  },
  {
    path: "master/product",
    component: AsynProductComponent,
    meta: { permission: true },
  },
  {
    path: "master/combo-product",
    component: AsynComboProductComponent,
    meta: { permission: true },
  },

  //list
  // {
  // 	path: 'views/reports/orders',
  // 	component: AsynOrderListComponent,
  // 	meta: { permission: true },
  // },
  {
    path: "views/customers",
    component: AsynCustomerListComponent,
    meta: { permission: true },
  },
  // {
  // 	path: 'views/ProductAvalibilityUpdate',
  // 	component: AsynProductAvailibilityUpdateComponent,
  // 	meta: { permission: true },
  // },
  {
    path: "views/reports/BranchSales",
    component: AsynBranchSellsReportComponent,
    meta: { permission: true },
  },
  {
    path: "reports/Sales",
    component: AsynSellsReportComponent,
    meta: { permission: true },
  },
  {
    path: "master/blogs",
    component: AsynBlogsComponent,
    meta: { permission: true },
  },

  {
    path: "user-settings",
    component: AsyncUserSettingsComponent,
    meta: { permission: true },
  },
  {
    path: "master/popup",
    component: AsynPopupComponent,
    meta: { permission: true },
  },
  //    {
  // 		path: 'sample-form',
  //       component:AsyncSampleFormComponent
  // 	}

  {
    path: "master/school",
    component: AsynSchoolComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("School_master")
          : false,
    },
  },
  {
    path: "master/import-schools",
    component: AsynImportSchoolComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Import_schools")
          : false,
    },
  },
  {
    path: "master/city",
    component: AsynCityComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("City_master")
          : false,
    },
  },
  {
    path: "master/brand",
    component: AsynBrandComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Brand_master")
          : false,
    },
  },
  {
    path: "master/supplier",
    component: AsynSupplierComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Supplier_master")
          : false,
    },
  },
  {
    path: "purchase-inventory/purchase",
    component: AsynPurchaseComponent,
    meta: {
      permission:
        permission && permission.PurchaseInventory !== undefined
          ? permission &&
            permission.PurchaseInventory.includes("Purchase_master")
          : false,
    },
  },
  {
    path: "purchase-inventory/purchase-history",
    component: AsynPurchaseHistoryComponent,
    meta: {
      permission:
        permission && permission.PurchaseInventory !== undefined
          ? permission &&
            permission.PurchaseInventory.includes("Purchase_history")
          : false,
    },
  },
  {
    path: "purchase-inventory/inventory-report",
    component: AsynInventoryReportComponent,
    meta: {
      permission:
        permission && permission.PurchaseInventory !== undefined
          ? permission &&
            permission.PurchaseInventory.includes("Inventory_report")
          : false,
    },
  },
  {
    path: "master/faq-category",
    component: AsynFaqCategoryComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("FAQ_Category_master")
          : false,
    },
  },
  {
    path: "master/faq",
    component: AsynFaqComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("FAQ_master")
          : false,
    },
  },
  {
    path: "master/warehouse",
    component: AsynWarehouseComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Warehouse_master")
          : false,
    },
  },
  {
    path: "views/reports/warehouse-orders",
    component: AsynWarehouseOrdersComponent,
    meta: {
      permission:
        permission?.WarehouseOrdersReport !== undefined
          ? permission.WarehouseOrdersReport.length > 0
          : false,
    },
  },
  {
    path: "views/reports/admin-orders",
    component: AsynAdminOrdersComponent,
    meta: {
      permission:
        permission?.AdminOrdersReport !== undefined
          ? permission.AdminOrdersReport.length > 0
          : false,
    },
  },
  {
    path: "reports/demanded-products",
    component: AsynDemandedProductsComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Demanded_products")
          : false,
    },
  },
  {
    path: "reports/unregistered-schools",
    component: AsynUnregisteredComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission &&
            permission.ReportMaster.includes("Unregistered_schools")
          : false,
    },
  },
  {
    path: "reports/purchase-logs",
    component: AsynPurchaseLogsComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Purchase_logs")
          : false,
    },
  },
  {
    path: "reports/abandoned-cart",
    component: AsynAbandonedCartComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Abandoned_cart")
          : false,
    },
  },
  {
    path: "reports/customers",
    component: AsynCustomersReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Customers")
          : false,
    },
  },
  {
    path: "reports/childrens",
    component: AsynChildrensReportComponent,
    meta: {
      permission:
        permission && permission.ReportMaster !== undefined
          ? permission && permission.ReportMaster.includes("Childrens")
          : false,
    },
  },
  {
    path: "views/reports/replacement",
    component: AsynReplacementReportComponent,
    meta: {
      permission:
        permission?.ReverseOrdersReport !== undefined
          ? permission.ReverseOrdersReport.length > 0
          : false,
    },
  },
  {
    path: "views/reports/warehouse-replacement-orders",
    component: AsynWarehouseReplacementOrdersComponent,
    meta: {
      permission:
        permission?.WarehouseReverseOrdersReport !== undefined
          ? permission.WarehouseReverseOrdersReport.length > 0
          : false,
    },
  },
];

export default routes;
