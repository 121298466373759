const initialState = {
  data: [],
  total: 1,
  totalAmount : 0,
  params: {},
  adminOrderData: [],
  cityPaginatedData: [],
}

const adminOrderReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_ADMIN_ORDERS':
      return {
        ...state,
        allData: action.allData,
        data: action.data.docs,
        total: action.totalPages,
        params: action.params,
        totalAmount : action.data.totalAmount
      }

    case 'GET_ADMIN_ORDERS_BY_ID_DATA':
      return {
        ...state,
        adminOrderData: action.data
      }

      case 'GET_CITY_DATA':
      return {
        ...state,
        cityPaginatedData: action.data
      }
   
    default:
      return { ...state }
  }
}
export default adminOrderReducer
