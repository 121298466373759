const initialState = {
    data: {}
}

const importSchoolReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_IMPORT_RESULTS':
            return {
                ...state,
                data: action.data
            }
        default:
            return { ...state }
    }
}
export default importSchoolReducer
