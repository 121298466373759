/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';

//  Dashboard 1
const AsyncDashboard1Component = Loadable({
	loader: () => import("views/Dashboard1"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Master user //
const AsyncUserMasterComponent = Loadable({
	loader: () => import("views/Master/UserMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("views/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("views/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 403 (Unauthorized page)
const AsyncErrorPage403Component = Loadable({
	loader: () => import("views/Error/403"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("views/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSampleFormComponent = Loadable({
	loader: () => import("views/SampleForm"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncFormInformationComponent = Loadable({
	loader: () => import("views/Master/FormInformation/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Master banner //
const AsyncBannerMasterComponent = Loadable({
	loader: () => import("views/Master/BannerMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynCategoryComponent = Loadable({
	loader: () => import("views/Master/CategoryMaster/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynExpenseComponent = Loadable({
	loader: () => import("views/Master/ExpenseCategoryMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncPaymentComponent = Loadable({
	loader: () => import("views/Master/PaymentMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncManageExpenseComponent = Loadable({
	loader: () => import("views/Master/ManageExpenseMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynSubCategoryComponent = Loadable({
	loader: () => import("views/Master/SubCategoryMaster/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynBranchComponent = Loadable({
	loader: () => import("views/Master/Branch"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynPromocodeComponent = Loadable({
	loader: () => import("views/Master/Promocode/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynSettingComponent = Loadable({
	loader: () => import("views/Master/Setting/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynNotificationComponent = Loadable({
	loader: () => import("views/Master/Notification/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynProductComponent = Loadable({
	loader: () => import("views/Master/Product/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

//list
const AsynOrderListComponent = Loadable({
	loader: () => import("views/Reports/Orders"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynCustomerListComponent = Loadable({
	loader: () => import("views/Customers"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// const AsynProductAvailibilityUpdateComponent = Loadable({
// 	loader: () => import("views/ProductAvailabilityUpdate/"),
// 	loading: () => <HulkPageLoader />,
// 	delay: 3000,
// });
const AsynBranchSellsReportComponent = Loadable({
	loader: () => import("views/Reports/BranchSellsReport"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynSellsReportComponent = Loadable({
	loader: () => import("views/Reports/SalesReport"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynBlogsComponent = Loadable({
	loader: () => import("views/Master/Blogs"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynPopupComponent = Loadable({
	loader: () => import("views/Master/Popup/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynSchoolComponent = Loadable({
	loader: () => import("views/Master/SchoolMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const AsynImportSchoolComponent = Loadable({
	loader: () => import("views/Master/ImportSchools"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const AsynCityComponent = Loadable({
	loader: () => import("views/Master/CityMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const AsynBrandComponent = Loadable({
	loader: () => import("views/Master/Brand"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const AsynComboProductComponent = Loadable({
	loader: () => import("views/Master/ComboProduct/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynSupplierComponent = Loadable({
	loader: () => import("views/Master/SupplierMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynPurchaseComponent = Loadable({
	loader: () => import("views/ProductInventory/PurchaseMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynPurchaseHistoryComponent = Loadable({
	loader: () => import("views/ProductInventory/PurchaseHistory"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynInventoryReportComponent = Loadable({
	loader: () => import("views/ProductInventory/InventoryReport"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynFaqCategoryComponent = Loadable({
	loader: () => import("views/Master/faqCategoryMaster/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynFaqComponent = Loadable({
	loader: () => import("views/Master/faqMaster/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynWarehouseComponent = Loadable({
	loader: () => import("views/Master/WarehouseMaster/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynWarehouseOrdersComponent = Loadable({
	loader: () => import("views/Reports/WarehouseOrders/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynAdminOrdersComponent = Loadable({
	loader: () => import("views/Reports/AdminOrders/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynDemandedProductsComponent = Loadable({
	loader: () => import("views/Reports/DemandedProducts/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynPurchaseLogsComponent = Loadable({
	loader: () => import("views/Reports/PurchaseLogs/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynAbandonedCartComponent = Loadable({
	loader: () => import("views/Reports/AbandonedCart/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynCustomersReportComponent = Loadable({
	loader: () => import("views/Reports/Customers/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsynChildrensReportComponent = Loadable({
	loader: () => import("views/Reports/Childrens/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynUnregisteredComponent = Loadable({
	loader: () => import("views/Reports/UnregisteredSchools/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynReplacementReportComponent = Loadable({
	loader: () => import("views/Reports/ReplacementReport/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsynWarehouseReplacementOrdersComponent = Loadable({
	loader: () => import("views/Reports/WarehouseReplacementOrders/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

export {
	AsyncDashboard1Component,
	AsyncUserMasterComponent,
	AsyncUserSettingsComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	AsyncErrorPage403Component,
	AsyncSampleFormComponent,
	AsyncFormInformationComponent,
	AsynCategoryComponent,
	AsynExpenseComponent,
	AsyncPaymentComponent,
	AsyncManageExpenseComponent,
	AsynBranchComponent,
	AsynPromocodeComponent,
	AsynSettingComponent,
	AsynNotificationComponent,
	AsynProductComponent,
	AsynOrderListComponent,
	AsynCustomerListComponent,
	// AsynProductAvailibilityUpdateComponent,
	AsynBranchSellsReportComponent,
	AsynSellsReportComponent,
	AsynSubCategoryComponent,
	AsyncBannerMasterComponent,
	AsynPopupComponent,
	AsynBlogsComponent,
	AsynSchoolComponent,
	AsynCityComponent,
	AsynBrandComponent,
	AsynComboProductComponent,
	AsynSupplierComponent,
	AsynPurchaseComponent,
	AsynPurchaseHistoryComponent,
	AsynInventoryReportComponent,
	AsynFaqCategoryComponent,
	AsynFaqComponent,
	AsynWarehouseComponent,
	AsynWarehouseOrdersComponent,
	AsynAdminOrdersComponent,
	AsynDemandedProductsComponent,
	AsynUnregisteredComponent,
	AsynPurchaseLogsComponent,
	AsynAbandonedCartComponent,
	AsynCustomersReportComponent,
	AsynChildrensReportComponent,
	AsynReplacementReportComponent,
	AsynWarehouseReplacementOrdersComponent,
	AsynImportSchoolComponent
};